<template>
	<div class="header">Choose Organization</div>
	<div class="chooseOrg body">
		<vSelect
			v-model="selectedOrg"
			@search="(v) => (orgSearch = v)"
			:options="orgList"
			label="title"
			placeholder="Organizations..."
			class="ba"
		/>
	</div>
	<div class="footer">
		<button @click="$emit('close')">Close</button>
		<button v-if="selectedOrg" @click="$emit('close', selectedOrg)" class="bgGrdPos">Next</button>
	</div>
</template>

<script>
	import { debounce } from "lodash"
	import { ref, computed, watch } from "vue"
	import { orgs } from "@/store"
	import vSelect from "vue-select"

	export default {
		name: "ChooseOrg",
		components: { vSelect },
		emits: ["close"],
		setup() {
			const orgStore = orgs()
			const orgSearch = ref("")
			const orgIds = ref([])
			const selectedOrg = ref(null)
			const orgList = computed(() => orgIds.value.map((id) => orgStore.orgs.value[id]))
			const fetchOrgs = debounce(async (search) => {
				const result = await orgStore.fetchOrgs({ search: search || "" })
				if (result) orgIds.value = result.ids
			}, 500)
			watch(orgSearch, fetchOrgs)
			fetchOrgs()

			return {
				selectedOrg,
				orgSearch,
				orgList
			}
		}
	}
</script>

<style lang="scss">
	.chooseOrg.body {
		z-index: 15;
	}
</style>
